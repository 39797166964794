import React, { useState, useEffect } from 'react';
import LoadingSpinner from './../../components/common/LoadingSpinner';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
  Table,
  Tooltip ,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  IconButton,
  Typography,
  Grid ,
  Box,
  Chip,
  MenuItem,
  InputLabel,
  Pagination,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton as MIconButton,
  Avatar
} from '@mui/material';
import {
  Edit,
  Delete,
  Add as AddIcon,
  ToggleOn,
  ToggleOff,
  Visibility,
  VisibilityOff,
  Close as CloseIcon,
  Description
} from '@mui/icons-material';
import { apiService } from './../../services/api/ApiService';
import NewUserForm from './../../components/NewUserForm';
import { UserProfile } from './../../services/api/type';
import DeleteConfirmationDialog from './../../components/DeleteConfirmationDialog';

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<UserProfile[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<UserProfile | null>(null);
  const [confirmName, setConfirmName] = useState('');
  const [avatarPreview, setAvatarPreview] = useState<string | undefined>('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState<UserProfile | null>(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [roles, setRoles] = useState<{ id: number; name: string; description: string }[]>([]);
  const [selectedRole, setSelectedRole] = useState<number | string>('');
  const [image, setImage] = useState<File | null>(null);
  const [accessLevel, setAccessLevel] = React.useState('');
const [permissions, setPermissions] = React.useState([
  "Transcriptor",
  "Precedentes",
  "SearchAI",
]);

const handleDeletePermission = (permissionToDelete) => {
  setPermissions((prev) => prev.filter((perm) => perm !== permissionToDelete));
};

useEffect(() => {
  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not available');
      }

      // Obtener y parsear el perfil del usuario desde el localStorage
      const userProfile = localStorage.getItem('userProfile');
      if (!userProfile) {
        throw new Error('User profile not found in localStorage');
      }
      const parsedUserProfile = JSON.parse(userProfile);
      const loggedUserId = parsedUserProfile.id;

      // Obtener la lista de usuarios desde la API
      const usersData = await apiService.getUsers();
      

      // Filtrar al usuario logueado de la lista
      const filteredData = usersData.filter((user: UserProfile) => user.id !== loggedUserId);

      // Actualizar el estado con los datos filtrados
      setUsers(filteredData);
      setFilteredUsers(filteredData);
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.message && error.message === "API Error: 403 - {\"message\":\"Forbidden\"}") {
        // Redirigir al login si se recibe un 403 Forbidden
        localStorage.clear();
        window.location.href='/session-closed';
      }
    } finally {
      setLoading(false);
    }
  };

  fetchUsers();
}, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesData = await apiService.getAdminRoles();
        if (Array.isArray(rolesData)) {
          // Filtra los roles para incluir solo "Manager" y "User"
          const filteredRoles = rolesData.filter((role: any) => 
            role.name === 'Manager' || role.name === 'User'
          );
  
          // Transforma los roles filtrados
          const transformedRoles = filteredRoles.map((role: any) => ({
            id: role.id || role.roleId || Math.random(), // Usa roleId si existe
            name: role.name || "Unknown Role",
            description: role.description || "No description available",
          }));
  
          setRoles(transformedRoles);
        } else {
          console.error("Roles data is not in the expected format", rolesData);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        if (error.message && error.message === "API Error: 403 - {\"message\":\"Forbidden\"}") {
          // Redirigir al login si se recibe un 403 Forbidden
          localStorage.clear();
          window.location.href='/session-closed';
        }
      }
    };
  
    fetchRoles();
  }, []);
  
  
  

  useEffect(() => {
    const searchLower = searchQuery.toLowerCase();
    const filtered = users.filter(user =>
      (user.name?.toLowerCase() || '').includes(searchLower) ||
      (user.email?.toLowerCase() || '').includes(searchLower) ||
      (user.role?.toLowerCase() || '').includes(searchLower)
    );
    setFilteredUsers(filtered);
    setPage(1);
  }, [searchQuery, users]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleOpenNewUserModal = () => {
    setOpenNewUserModal(true);
  };

  const handleCloseNewUserModal = () => {
    setOpenNewUserModal(false);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(1);
  };

  // const toggleUserStatus = async (id: number) => {
  //   const user = users.find((u) => u.id === id);
  //   if (user) {
  //     const newStatus = user.status === 'ACTIVE' ? 'AWAITING_APPROVAL' : 'ACTIVE';
  //     try {
  //       const token = localStorage.getItem('token');
  //       if (!token) {
  //         throw new Error('Token not available');
  //       }
  //       await apiService.updateUserStatus(id, newStatus);
  //       setUsers((prevUsers) =>
  //         prevUsers.map((user) =>
  //           user.id === id
  //             ? { ...user, status: newStatus }
  //             : user
  //         )
  //       );
  //     } catch (error) {
  //       console.error('Error updating user status:', error);
  //     }
  //   }
  // };

  const toggleUserStatus = async (id: number) => {
    const user = filteredUsers.find((u) => u.id === id);
    if (user) {
      const newStatus = user.status === 'ACTIVE' ? 'AWAITING_APPROVAL' : 'ACTIVE';
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not available');
        }
        await apiService.updateUserStatus(id, newStatus);
        
        // Actualiza solo filteredUsers para mantener la paginación y la vista actual
        setFilteredUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === id
              ? { ...user, status: newStatus }
              : user
          )
        );
      } catch (error) {
        console.error('Error updating user status:', error);
        if (error.message && error.message === "API Error: 403 - {\"message\":\"Forbidden\"}") {
          // Redirigir al login si se recibe un 403 Forbidden
          localStorage.clear();
          window.location.href='/session-closed';
        }
      }
    }
  };
  

  const getStatusColor = (status: 'ACTIVE' | 'AWAITING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'DELETED' | null) => {
    if (status === null) return 'default';
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'AWAITING_APPROVAL':
        return 'warning';
      case 'APPROVED':
        return 'info';
      case 'REJECTED':
        return 'error';
      case 'DELETED':
        return 'secondary';  // O el color que prefieras para mostrar usuarios eliminados
      default:
        return 'default';
    }
  };
  
  

  const handleOpenDeleteModal = (user: UserProfile) => {
    setUserToDelete(user);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteDialog(false);
    setUserToDelete(null);
    setConfirmName('');
  };
  const handleCloseDeleteDialog = () => {
    setUserToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    
      try {
        // Llamada a la API para eliminar el usuario
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not available');
        }
  
        // Llamada a la API de eliminación
        await apiService.deleteUser(userToDelete.id);  // Asumiendo que `apiService.deleteUser` existe
  
        // Actualizar el estado de los usuarios
        setUsers(prevUsers => prevUsers.filter(user => user.id !== userToDelete?.id));
        setFilteredUsers(prevUsers => prevUsers.filter(user => user.id !== userToDelete?.id));
        handleCloseDeleteModal();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
  };
  

  const handleOpenEditModal = (user: UserProfile) => {
    
    setEditingUser(user);
    setUsername(user.username || '');
    setEmail(user.email || '');
    setName(user.name || '');
    setLastName(user.lastName || '');
    setAvatar(user.avatar || '');
    setAvatarPreview(user.avatar || '');
    setNewPassword('');
    setConfirmPassword('');
    setSelectedRole(user.role); // Assuming 'roleId' exists in the UserProfile type
    setOpenEditModal(true);
    
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setEditingUser(null);
  };

  const handleSubmitEdit = async () => {
    // if (newPassword !== confirmPassword) {
    //   alert('Las contraseñas no coinciden.');
    //   return;
    // }

    if (editingUser) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not available');
        }

        await apiService.updateUser(editingUser.id, {
          username,
          email,
          name,
          lastName,
          ...(avatar && { avatar }),
        });

        const roleObject = roles.find(role => role.name === selectedRole);
  
  if (roleObject) {
    const role_id = roleObject.id;
    await apiService.updateUserRole(editingUser.id, role_id);
  } else {
    console.error('Role not found:', selectedRole);
  }

        setUsers(prevUsers =>
          prevUsers.map(user =>
            user.id === editingUser.id
              ? { ...user, username, email, name, lastName, avatar, role: roles.find(r => r.id === selectedRole)?.name || user.role }
              : user
          )
        );

        setFilteredUsers(prevUsers =>
          prevUsers.map(user =>
            user.id === editingUser.id
              ? { ...user, username, email, name, lastName, avatar, role: roles.find(r => r.id === selectedRole)?.name || user.role }
              : user
          )
        );

        handleCloseEditModal();
      } catch (error) {
        console.error('Error updating user:', error);
      }
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result as string);
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCreateNewUser = async (newUser: UserProfile) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not available');
      }
      const createdUser = await apiService.addUser(newUser);
      setUsers(prevUsers => [...prevUsers, createdUser]);
      setFilteredUsers(prevUsers => [...prevUsers, createdUser]);
      handleCloseNewUserModal();
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  if (loading) return <LoadingSpinner message="Cargando Usuarios" />;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <TextField
          placeholder="Buscar por nombre, email, rol, etc."
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ maxWidth: '776px', mr: 2 }}
        />
        <Button
          variant="contained"
          sx={{ backgroundColor: '#002E74', color: '#fff', fontSize: '14px', height: '40px' }}
          startIcon={<AddIcon />}
          onClick={handleOpenNewUserModal}
        >
          Nuevo usuario
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="user table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Rol</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((user) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={user.avatar || ''} sx={{ mr: 2 }} />
                    {user.name + " " + user.lastName}
                  </Box>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  <Chip
                    label={user.status === 'ACTIVE' ? 'Activo' : 'Inactivo'}
                    color={getStatusColor(user.status)}
                    icon={user.status === 'ACTIVE' ? <ToggleOn /> : <ToggleOff />}
                    onClick={() => toggleUserStatus(user.id)}
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleOpenEditModal(user)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleOpenDeleteModal(user)}>
  <Delete />
</IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(filteredUsers.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}
      />

      
         <DeleteConfirmationDialog
        open={openDeleteDialog}
        userName={userToDelete?.name || ''}
        onClose={handleCloseDeleteDialog}
        onDelete={handleConfirmDelete}
      />
        

        <Dialog open={openEditModal} onClose={handleCloseEditModal} maxWidth="sm" fullWidth>
  <DialogTitle>Editar Usuario</DialogTitle>
  <DialogContent
  sx={{
    maxHeight: "60vh", // Define una altura máxima si el contenido es muy grande
    overflowY: "auto", // Habilita el scroll vertical
    "&::-webkit-scrollbar": {
      display: "none", // Oculta la barra de scroll en navegadores WebKit
    },
    "-ms-overflow-style": "none", // Oculta la barra de scroll en IE y Edge
    "scrollbar-width": "none", // Oculta la barra de scroll en Firefox
  }}
>
    <Box sx={{ padding: 3 }}>
      {/* Avatar Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        <Avatar
          sx={{
            width: 150,
            height: 150,
            borderRadius: "4px",
            backgroundColor: "#f0f0f0",
            border: "1px solid #dcdcdc",
          }}
          src={avatarPreview || undefined}
        >
          {avatarPreview ? null : "IMG"}
        </Avatar>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="avatar-upload"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="avatar-upload">
          <Tooltip title="Subir imagen de perfil">
            <IconButton color="primary" component="span">
              <PhotoCamera />
            </IconButton>
          </Tooltip>
        </label>
      </Box>

      {/* Form Section */}
      <Box
        component="form"
        onSubmit={handleSubmitEdit}
        sx={{ display: "flex", flexDirection: "column", gap: 3 }}
      >
        <Grid container spacing={2}>
          {/* Name Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Nombre"
              placeholder="Escribe el nombre aquí"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!name} // Ejemplo de validación simple
              helperText={!name ? "El nombre es obligatorio" : ""}
            />
          </Grid>

          {/* Last Name Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Apellido"
              placeholder="Escribe el apellido aquí"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              error={!lastName}
              helperText={!lastName ? "El apellido es obligatorio" : ""}
            />
          </Grid>

          {/* Email Input (Disabled) */}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Correo Electrónico"
              value={email}
              disabled // Campo deshabilitado
              InputProps={{
                readOnly: true, // Asegura que sea de solo lectura
              }}
              sx={{
                backgroundColor: "#f9f9f9", // Color para distinguir el campo deshabilitado
              }}
            />
          </Grid>
        </Grid>

        {/* Role Selection */}
        <Box>
  <Typography variant="body1" sx={{ marginBottom: 1 }}>
    Rol Asignado
  </Typography>
  <Select
    variant="outlined" // Igual que los TextField
    fullWidth
    value={selectedRole}
    onChange={(e) => setSelectedRole(e.target.value)}
    displayEmpty
    sx={{
      borderRadius: "4px", // Igual que los TextField
      backgroundColor: "#ffffff", // Igual al fondo de los TextField
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#dcdcdc", // Color de borde similar al de TextField
        },
        "&:hover fieldset": {
          borderColor: "#a3a3a3", // Borde en hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "#3f51b5", // Borde cuando está enfocado
        },
      },
    }}
  >
    <MenuItem value="" disabled>
      Selecciona un rol
    </MenuItem>
    {roles.map((role) => (
      <MenuItem key={role.id} value={role.name}>
        {role.name}
      </MenuItem>
    ))}
  </Select>
</Box>
      </Box>
    </Box>
  </DialogContent>

  {/* Action Buttons */}
  <DialogActions>
    <Button
      onClick={handleCloseEditModal}
      color="secondary"
      variant="outlined"
      sx={{ marginRight: 2 }}
    >
      Cancelar
    </Button>
    <Button
      type="submit"
      color="primary"
      variant="contained"
      onClick={handleSubmitEdit}
    >
      Guardar
    </Button>
  </DialogActions>
</Dialog>



      <Dialog open={openNewUserModal} onClose={handleCloseNewUserModal} maxWidth="sm" fullWidth>
        <NewUserForm onSubmit={handleCreateNewUser} onClose={handleCloseNewUserModal} />
      </Dialog>

    </Box>
  );
};

export default UserManagement;