import * as React from 'react';

import {
  Box,
  Typography,
  Collapse,
  Card,
  List,
  Snackbar,
  IconButton,
  Button,
  Alert,
  Grid,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'; 
import { useState, useEffect } from 'react';
import { apiService } from './../services/api/ApiService';
import FeedbackForm from './FeedbackForm';
import imgNoPrecedent from '../assets/Precedente_not_found.png'

// Interface for Precedent object
interface Precedent {
  title: string;
  date: string;
  sentencia_name: string;
  sentencia_url: string;
  status: string;
  description: string;
  justification: string;
  relevant: string;
  similarity: number;
  resumen_principal: string;
  resultado_sentencia: string;
  additional_info?: string;
}

interface PrecedentProps {
  precedent: Precedent;
  onCopy: (text: string) => void;
  onClick: (precedent: Precedent) => void;
  isActive: boolean;
}

interface ViewPrecedentProps {
  setActiveItem: (item: string) => void; // Use this to update the active view
}



const formatReport = (report: string) => {
  const lines = report.split('\n');
  let formattedReport = '';
  let inList = false;

  lines.forEach((line) => {
    line = line.trim();

    if (/^\d+\.\s/.test(line)) {
      // Heading detected
      if (inList) {
        formattedReport += '</ul>';
        inList = false;
      }
      formattedReport += `<h3>${line}</h3>`;
    } else if (/^[-*•]\s/.test(line)) {
      // List item detected
      if (!inList) {
        formattedReport += '<ul>';
        inList = true;
      }
      formattedReport += `<li>${line.slice(2)}</li>`;
    } else if (line === '') {
      // Empty line
      if (inList) {
        formattedReport += '</ul>';
        inList = false;
      }
    } else {
      // Regular paragraph
      formattedReport += `<p>${line}</p>`;
    }
  });

  if (inList) {
    formattedReport += '</ul>';
  }

  return formattedReport;
};


const PrecedentItem: React.FC<PrecedentProps> = ({ precedent, onCopy, onClick, isActive }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

const handleClick = () => {
  setExpanded(!expanded);
};
  
const handleCopy = () => {
  navigator.clipboard.writeText(precedent.justification)
    .then(() => {
      setOpenSnackbar(true); // Mostrar un mensaje de éxito
    })
    .catch(() => {
      setOpenSnackbar(true); // Mostrar un mensaje de error si no se puede copiar
    });
};
  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const maxTextLength = 200;
  const isTextLong = precedent.resultado_sentencia.length > maxTextLength;

  return (
    <Card
      sx={{
        mb: 2,
        backgroundColor: precedent.relevant === 'Relevante' ? 'green.100' : 'red.100',
        borderRadius: 2,
        boxShadow: 1,
        p: 3,
      }}
      onClick={() => onClick(precedent)}
    >
      <Box display="flex" justifyContent="space-between" mb={2}>
      <Typography
  variant="h6"
  sx={{
    fontFamily: 'Montserrat', // Fuente Montserrat
    fontSize: '40px', // Tamaño de fuente 40px
    fontWeight: 600, // Peso de fuente 600
    lineHeight: '48.76px', // Altura de línea 48.76px
    textAlign: 'left', // Alineación a la izquierda
    textUnderlinePosition: 'from-font', // Posición del subrayado
    textDecorationSkipInk: 'none', // Desactiva el salto de tinta para subrayados
    mb: 1, // Margen inferior (equivalente a mb={1})
  }}
>
  {precedent.sentencia_name}
</Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onCopy(precedent.resumen_principal);
            }}
          >
            <ContentCopyIcon sx={{ color: 'blue.600', fontSize: 'small' }} />
            Copiar Texto
          </Button>
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              window.open(precedent.sentencia_url, '_blank');
            }}
          >
            <DownloadIcon sx={{ color: 'blue.600', fontSize: 'small' }} />
            Descargar Precedente
          </Button>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <Typography variant="body2" mb={2} sx={{ flex: 1 }}>
          {precedent.resumen_principal}
        </Typography>

        <Box
          sx={{
            backgroundColor: '#002E74',
            color: 'white',
            borderRadius: '4px',
            width: '100px',
            textAlign: 'center',
            padding: '22px',
            ml: 2,
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
            {Math.round(precedent.similarity * 100)}%
          </Typography>
          <Typography sx={{ fontSize: '12px' }}>Similitud</Typography>
        </Box>
      </Box>
      {precedent.justification && (
  <Box
    sx={{
      backgroundColor:
        precedent.relevant === 'Relevante' ? '#32936F29' : 'rgba(245, 126, 119, 0.16)', // Fondo con color dinámico según relevancia
      border: `1px dashed ${precedent.relevant === 'Relevante' ? '#2F7B5E' : '#D83B32'}`, // Borde punteado con color según relevancia
      borderRadius: '8px',
      padding: 3,
      marginBottom: 2,
    }}
  >
    <Box display="flex" alignItems="start" marginBottom={2}>
      <i className="fas fa-info-circle" style={{ color: '#3B82F6', fontSize: '1.5rem', marginRight: '8px' }}></i>
      <Typography
  variant="body1"
  sx={{
    color: precedent.relevant === 'Relevante' ? '#2F7B5E' : '#D83B32', // Color del texto según relevancia
    fontFamily: 'Montserrat', // Fuente Montserrat
    fontSize: '32px', // Tamaño de fuente 32px
    fontWeight: 600, // Peso de fuente 600
    lineHeight: '39.01px', // Altura de línea 39.01px
    textAlign: 'left', // Alineación a la izquierda
    textUnderlinePosition: 'from-font', // Posición del subrayado
    textDecorationSkipInk: 'none', // Desactiva el salto de tinta para subrayados
  }}
>
  Justificación
</Typography>
 {/* Contenedor flex para los botones */}
 <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={2} width="100%">
  {/* Botón "Copiar texto" */}
  <Button
    onClick={handleCopy}
    sx={{
      textTransform: 'none',
      color: precedent.relevant === 'Relevante' ? '#2F7B5E' : '#D83B32',
    }}
  >
    <ContentCopyIcon sx={{ color: 'blue.600', fontSize: 'small' }} />
    Copiar texto
  </Button>

  {/* Botón de la flecha */}
  <Button
    onClick={handleClick}
    sx={{
      textTransform: 'none', // Evita que el texto del botón se transforme en mayúsculas
      color: precedent.relevant === 'Relevante' ? '#2F7B5E' : '#D83B32', // Color del texto según relevancia
      marginLeft: 2, // Añade un margen entre los botones
    }}
  >
    {expanded ? <ArrowUpward /> : <ArrowDownward />} {/* Cambia entre flecha arriba y abajo */}
  </Button>
</Box>


    </Box>

    <Collapse in={expanded}>
    <Typography
  variant="body2"
  sx={{
    color: precedent.relevant === 'Relevante' ? '#2F7B5E' : '#D83B32', // Color del texto según relevancia
    fontFamily: 'Montserrat', // Fuente Montserrat
    fontSize: '16px', // Tamaño de fuente 16px
    fontWeight: 400, // Peso de fuente 400 (normal)
    lineHeight: '28px', // Altura de línea 28px
    textAlign: 'left', // Alineación a la izquierda
    textUnderlinePosition: 'from-font', // Posición del subrayado
    textDecorationSkipInk: 'none', // Desactiva el salto de tinta para subrayados
  }}
>
  {precedent.justification}
</Typography>

    </Collapse>

   
  </Box>
)}



      <Box sx={{ borderBottom: '1px solid #ccc', mb: 2 }} />

      {precedent.additional_info && (
        <Typography variant="body2" color="textSecondary" mb={2}>
          Información adicional: <strong>{precedent.additional_info}</strong>
        </Typography>
      )}

      <Typography variant="body2" mb={1}>
        {showFullText ? precedent.resultado_sentencia : `${precedent.resultado_sentencia.substring(0, maxTextLength)}...`}
      </Typography>

      <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onCopy(precedent.resultado_sentencia);
            }}
          >
            <ContentCopyIcon sx={{ color: 'blue.600', fontSize: 'small' }} />
            Copiar Texto
          </Button>

      {isTextLong && (
        <Button onClick={toggleText} color="primary">
          {showFullText ? 'Leer menos' : 'Leer más'}
        </Button>
      )}
    </Card>

  );
};

const ViewPrecedent: React.FC<ViewPrecedentProps> = ({ setActiveItem }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [copiedText, setCopiedText] = useState<string | null>(null);
  const [precedents, setPrecedents] = useState<Precedent[]>([]);
  const [activeItem, setActiveItemLocal] = useState<Precedent | null>(null); // Local active item state

  const selectedPrecedent = JSON.parse(localStorage.getItem('selectedPrecedent') || 'null');
  const pdfFileName = selectedPrecedent ? selectedPrecedent.pdf_file_name : '';

  useEffect(() => {
    const fetchPrecedents = async () => {
      try {
      if (pdfFileName) {
        const precedentData = await apiService.getPrecedenteInfo(pdfFileName);

        // Ordenar precedentes por similarity de mayor a menor
        console.log({precedentData})
        const sortedPrecedents = precedentData.sort((a, b) => b.similarity - a.similarity);
        setPrecedents(sortedPrecedents);
      } else {
        console.error('No pdf_file_name found in selectedPrecedent');
      }
    } catch (err: any) {
      console.log({err})
      if (err.message && err.message === "API Error: 403 - {\"message\":\"Forbidden\"}") {
        // Redirigir al login si se recibe un 403 Forbidden
        localStorage.clear();
        //navigate('/session-closed');
      } else {
        console.log('Error fetching transcriptions');
      }
    }
    };

    fetchPrecedents();
  }, [pdfFileName]);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopiedText(text);
        setSnackbarOpen(true);
      })
      .catch(err => console.error('Error al copiar texto: ', err));
  };

  const handleClickItem = (precedent: Precedent) => {
    setActiveItemLocal(precedent === activeItem ? null : precedent);
  };

  return (
    <Box sx={{ p: 6, backgroundColor: 'gray.100' }}>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        {/* <IconButton sx={{ mr: 2 }} onClick={() => setActiveItem('Precedente')}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ color: '#718096' }} />
        </IconButton> */}
        <Typography variant="body2" color="textSecondary">
          Análisis de Precedentes / {selectedPrecedent.name}
        </Typography>
      </Box>

      <Typography variant="h3" component="h2" gutterBottom fontWeight="bold" color="text-blue-600">
        {selectedPrecedent.name}
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Cantidad de precedentes encontrados: <span style={{ fontWeight: 'bold', color: '#1E90FF' }}>{precedents.length}</span>
      </Typography>

      <Card sx={{ mt: 3, mb: 2, backgroundColor: 'white', borderRadius: 2, p: 4, boxShadow: 1 }}>
        <Typography variant="h6" gutterBottom fontWeight="bold">
          Información importante
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ backgroundColor: 'gray.50', p: 4, borderRadius: 1 }}>
              <Typography variant="body2" fontWeight="semibold">Caso:</Typography>
              <Typography variant="body2">{selectedPrecedent ? selectedPrecedent.name : 'No disponible'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ backgroundColor: 'gray.50', p: 4, borderRadius: 1 }}>
              <Typography variant="body2" fontWeight="semibold">Número de caso/precedente:</Typography>
              <Typography variant="body2">{selectedPrecedent ? selectedPrecedent.numberCase : 'No disponible'}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ mb: 2, p: 4 }}>
        {/* <Typography variant="h6" gutterBottom fontWeight="bold">
          Resumen
        </Typography> */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: formatReport(selectedPrecedent.resumen_pdf) }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FeedbackForm />
          </Grid>
        </Grid>
      </Card>

      <Typography variant="h6" gutterBottom fontWeight="bold" color="text-blue-600">
        Precedentes encontrados
      </Typography>

      <List>
  {precedents.length > 0 ? (
    precedents.map((precedent, index) => (
      <PrecedentItem
        key={index}
        precedent={precedent}
        onCopy={handleCopy}
        onClick={handleClickItem}
        isActive={activeItem === precedent}
      />
    ))
  ) : (
    // CAMBIO: Muestra la imagen y el mensaje cuando no hay precedentes
    <Box sx={{ textAlign: 'center', mt: 5 }}>
      <img
        src={imgNoPrecedent}
        alt="No Precedent Found"
        style={{ width: '452px', height: 'auto', marginBottom: '16px' }}
      />
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Montserrat', // CAMBIO: Fuente del texto
          fontSize: '24px',         // CAMBIO: Tamaño de la fuente
          fontWeight: 500,          // CAMBIO: Peso de la fuente
          lineHeight: '32px',       // CAMBIO: Altura de línea
          textAlign: 'center',      // CAMBIO: Alineación del texto
          color: '#AAAAAA'
        }}
      >
        No se encontró precedente
      </Typography>
    </Box>
  )}
</List>


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          ¡Texto copiado al portapapeles!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ViewPrecedent;