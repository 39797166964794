import React, { useState, useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import logoTFJA from '../assets/Logo_Tribunal_Federal_de_Justicia.png';
import fondoTFJA from '../assets/fondo.jpeg';

const Home: React.FC = () => {
  const [user, setUser] = useState<{ username: string; name: string } | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Cargar los datos del usuario desde localStorage
    const storedUser = localStorage.getItem('userProfile');
    if (!storedUser) {
      // Si no hay un usuario almacenado, redirigir a /session-closed
      navigate('/session-closed');
      return;
    }
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);

      // Redirigir a /session-inactive si el estado es INACTIVE
      if (parsedUser.status === 'AWAITING_APPROVAL') {
        navigate('/session-inactive');
      }
    }
  }, [navigate]);

  return (
    <Container 
      maxWidth={false} // Hace que el contenedor ocupe todo el ancho de la pantalla
      disableGutters // Remueve los márgenes predeterminados del contenedor
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', // Centra el contenido verticalmente
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
          borderRadius: '4px',
          textAlign: 'center',
          width: '95%', // Ocupa todo el ancho
          height: '179px', // Ocupa todo el alto
          backgroundImage: `url(${fondoTFJA})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#000000', // Texto en negro para el saludo inicial
          fontFamily: 'Montserrat', // Estableciendo la fuente Montserrat
          fontSize: '40px', // Tamaño de fuente
          fontWeight: 600, // Peso de la fuente
          lineHeight: '48.76px', // Altura de la línea
        }}
      >
        {/* Personalizando colores específicos en el texto */}
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ mb: 2, color: '#000000' }}
        >
          ¡Hola, {user ? user.name : 'usuario'}! 
          <span style={{ color: '#002E74' }}> ¡Nos alegra verte de nuevo!</span>
        </Typography>

        <Typography variant="body1" sx={{ mb: 3, color: '#000000' }}>
          Estamos trabajando en nuevas funcionalidades para mejorar tu experiencia. ¡Mantente atento a las actualizaciones!
        </Typography>
      </Box>

      {/* Logo centrado */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <img
          src={logoTFJA}
          alt="Logo TFJA"
          style={{ maxWidth: '268px', height: 'auto', marginTop:'35px'}} // Ajusta el tamaño del logo
        />
      </Box>

    </Container>
  );
};

export default Home;
