import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Avatar,
  Grid,
  Paper,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import colors from './../styles/Colors';
// import { updateUserProfile } from './../services/api/authApi';
import { apiService } from './../services/api/ApiService';
import LoadingSpinner from './common/LoadingSpinner';
import NotificationBanner from './common/NotificationBanner';
import { UserProfile } from '../services/api/type';

const MAX_FILE_SIZE = 1048576; // 1 MB
const MIN_IMAGE_DIMENSIONS = 150; // Dimensiones mínimas
const MAX_IMAGE_WIDTH = 500; // Ancho máximo para la imagen redimensionada
const MAX_IMAGE_HEIGHT = 500; // Alto máximo para la imagen redimensionada

const ProfileEmployer: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile>({
    id: 0,
    username: '',
    email: '',
    password: '',
    googleId: null,
    name: '',
    lastName: '',
    avatar: '',
    status: 'ACTIVE',
    role: '',
    roleId: '',
    createdAt: '',
    updatedAt: '',
  });

  const [profileImage, setProfileImage] = useState<string | ArrayBuffer | null>(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    type: 'success' as 'success' | 'error',
    title: '',
    message: '',
    open: false,
  });

  useEffect(() => {
    const storedUser = localStorage.getItem('userProfile');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setProfile({ ...user });
      setProfileImage(user.avatar || '');
    }
  }, []);

  const handleProfileChange = (
    event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name as string]: value as string }));
  };

  // const handleSaveProfile = async () => {
  //   setLoading(true);
  //   try {
  //     const updatedProfile = await apiService.updateUserProfile(profile); 
      
  //     if (updatedProfile) {
  //       const userProfile = localStorage.getItem('userProfile');

  //       // Actualizar las propiedades con los nuevos valores
  //       userProfile.name = updatedProfile.name;
  //       userProfile.lastname = updatedProfile.lastname;
  //       userProfile.role = updatedProfile.role;
  //       userProfile.status = updatedProfile.status;

  //       // Guardar el perfil actualizado en localStorage
  //       localStorage.setItem('userProfile', JSON.stringify(userProfile));
  //     } else {
  //       console.warn('No se encontró un perfil en localStorage.');
  //     }
      
  //     localStorage.setItem('userProfile', JSON.stringify(userProfileString));
  //     setNotification({
  //       type: 'success',
  //       title: 'Éxito',
  //       message: 'Perfil actualizado con éxito!',
  //       open: true,
  //     });
  //   } catch (error) {
  //     console.error('Error al actualizar el perfil:', error);
  //     setNotification({
  //       type: 'error',
  //       title: 'Error',
  //       message: 'Error al actualizar el perfil.',
  //       open: true,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSaveProfile = async () => {
    setLoading(true);
    try {
      const updatedProfile = await apiService.updateUserProfile(profile);
  
      if (updatedProfile) {
        // Recuperar el perfil desde localStorage y convertirlo a un objeto
        const userProfileString = localStorage.getItem('userProfile');
        if (userProfileString) {
          const userProfile = JSON.parse(userProfileString);
  
          // Actualizar las propiedades con los nuevos valores
          userProfile.name = updatedProfile.name;
          userProfile.lastName = updatedProfile.lastName;
          //userProfile.role = updatedProfile.role;
          //userProfile.status = updatedProfile.status;
  
          // Guardar el perfil actualizado en localStorage
          localStorage.setItem('userProfile', JSON.stringify(userProfile));
        } else {
          console.warn('No se encontró un perfil en localStorage.');
        }
      }
  
      setNotification({
        type: 'success',
        title: 'Éxito',
        message: 'Perfil actualizado con éxito!',
        open: true,
      });
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Error al actualizar el perfil.',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };
  
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Verificar el tamaño del archivo
      if (file.size > MAX_FILE_SIZE) {
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'El archivo es demasiado grande. Debe ser menor a 1MB.',
          open: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        // Crear una imagen para verificar las dimensiones
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const { width, height } = img;

          // Verificar si la imagen es demasiado pequeña
          if (width < MIN_IMAGE_DIMENSIONS || height < MIN_IMAGE_DIMENSIONS) {
            setNotification({
              type: 'error',
              title: 'Error',
              message: `La imagen es demasiado pequeña. Debe ser al menos de ${MIN_IMAGE_DIMENSIONS}x${MIN_IMAGE_DIMENSIONS} píxeles.`,
              open: true,
            });
            return;
          }

          // Redimensionar y comprimir la imagen si es necesario
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let newWidth = width;
          let newHeight = height;

          // Ajustar dimensiones manteniendo la proporción
          if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
            if (width > height) {
              newWidth = MAX_IMAGE_WIDTH;
              newHeight = (height * MAX_IMAGE_WIDTH) / width;
            } else {
              newHeight = MAX_IMAGE_HEIGHT;
              newWidth = (width * MAX_IMAGE_HEIGHT) / height;
            }
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          if (ctx) {
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(
              (blob) => {
                if (blob && blob.size > MAX_FILE_SIZE) {
                  setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'La imagen redimensionada aún es demasiado grande. Intenta una imagen más pequeña.',
                    open: true,
                  });
                  return;
                }

                // Leer el blob como Data URL para mostrar la imagen
                const newFileReader = new FileReader();
                newFileReader.onloadend = () => {
                  setProfileImage(newFileReader.result);
                  setProfile((prevProfile) => ({
                    ...prevProfile,
                    avatar: newFileReader.result as string,
                  }));
                };
                if (blob) newFileReader.readAsDataURL(blob);
              },
              'image/jpeg', // Cambiar a formato JPEG
              0.7 // Nivel de calidad (0.7 = 70% de la calidad original)
            );
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ p: 3, margin: '10px' }}>
      {loading && <LoadingSpinner />}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Box sx={{ position: 'relative', width: 'fit-content' }}>
              <Avatar
                alt={`${profile.name} ${profile.lastName}`}
                src={profileImage as string}
                sx={{
                  width: 150,
                  height: 150,
                  borderRadius: '4px',
                  border: `2px solid ${colors.colorPrimary}`,
                }}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  backgroundColor: 'background.paper',
                }}
              >
                <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                <PhotoCamera />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  value={profile.name}
                  onChange={handleProfileChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Apellido"
                  name="lastName"
                  value={profile.lastName}
                  onChange={handleProfileChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="E-mail"
                  name="email"
                  value={profile.email}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Select
                  fullWidth
                  label="Estado"
                  name="estado"
                  value={profile.estado}
                  onChange={handleProfileChange as any}
                  variant="outlined"
                  disabled
                >
                  <MenuItem value="Ciudad de México">Ciudad de México</MenuItem>
                 
                </Select>
              </Grid> */}
            </Grid>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: colors.colorPrimary }}
                onClick={handleSaveProfile}
              >
                Guardar Perfil
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <NotificationBanner
        type={notification.type}
        title={notification.title}
        message={notification.message}
        open={notification.open}
        onClose={handleCloseNotification}
      />
    </Box>
  );
};

export default ProfileEmployer;
