import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';  // Icono para el título "Necesitas Ayuda"
import { apiService } from './../services/api/ApiService';
import SimpleNotification from './SimpleNotification';  // Importa la notificación

interface FaqItemProps {
  question: string;
  answer: string;
}

const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(prev => !prev);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange} sx={{ mb: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const FaqPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [faqData, setFaqData] = useState<FaqItemProps[]>([
    { question: '¿Cuál es el tamaño máximo permitido para subir un archivo?', answer: 'El tamaño máximo permitido para subir un archivo es de 100MB.' },
    { question: '¿El proceso de transcripción es inmediato?', answer: 'El proceso de transcripción puede tardar unos minutos dependiendo de la duración del archivo y la carga del servidor.' },
    { question: '¿Qué transcripciones están disponibles para los usuarios?', answer: 'Los usuarios tienen acceso a transcripciones en texto y en formato de subtítulos (SRT, VTT).' },
    { question: '¿Cuáles son los formatos aceptados para la transcripción?', answer: 'Los formatos aceptados incluyen MP3, MP4, WAV, y FLAC.' },
    { question: '¿Es posible subir más de un archivo por transcripción?', answer: 'Actualmente, solo es posible subir un archivo por transcripción. Para procesar múltiples archivos, súbelos individualmente.' },
    { question: '¿Cuáles son las causas que pueden generar un error en las transcripciones?', answer: 'Errores en la transcripción pueden deberse a archivos corruptos, formatos no soportados o problemas de conexión durante la carga del archivo.' },
    { question: '¿Existe un manual de usuario?', answer: 'Sí, puedes encontrar el manual de usuario en la sección de ayuda de nuestra plataforma o solicitarlo a soporte.' },
    { question: '¿Es posible procesar más de una transcripción simultáneamente?', answer: 'No, las transcripciones se procesan una a la vez. Deberás esperar a que una transcripción termine antes de iniciar otra.' },
    { question: '¿Cuál es la diferencia entre relevancia y similitud?', answer: 'La similitud mide qué tan parecido es un precedente con otro en términos generales, como la estructura del caso, el texto o los hechos involucrados. Por otro lado, la relevancia evalúa si un precedente tiene un valor significativo para el caso original, determinando si aporta información clave o relevante para el análisis.' }
  ]);

  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Estados para la notificación
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationType, setNotificationType] = useState<'success' | 'error'>('success');
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSubmitComment = async () => {
    if (!comment) {
      setNotificationType('error');
      setNotificationMessage('Por favor, escribe un comentario antes de enviar.');
      setNotificationOpen(true);
      return;
    }

    setIsSubmitting(true);
    setNotificationMessage('');
    
    try {
      const userProfile = JSON.parse(localStorage.getItem('userProfile') || 'null');
      const userId = userProfile.id;
      const pdfFileName = 'Soporte';
      const servico = 'Soporte';  

      await apiService.sendComment(comment, userId, 1, pdfFileName, servico);

      setComment('');
      setNotificationType('success');
      setNotificationMessage('Comentario enviado con éxito.');
    } catch (error) {
      setNotificationType('error');
      setNotificationMessage('Hubo un error al enviar el comentario. Inténtalo de nuevo más tarde.');
    } finally {
      setIsSubmitting(false);
      setNotificationOpen(true);
    }
  };

  const filteredFaqData = faqData.filter(item =>
    item.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ fontFamily: 'Roboto, sans-serif', backgroundColor: 'white', margin: '10px' }}>
      {/* Header */}
      <Box sx={{ backgroundColor: '#002E7480', color: 'white', textAlign: 'center', py: 4 }}>
        <Typography variant="h1" sx={{ fontSize: '2.5em', mb: 2 }}>¿Tienes alguna pregunta o duda?</Typography>
        <Typography variant="h6" sx={{ fontSize: '1.2em', mb: 3, mx: 'auto', maxWidth: '63%' }}>
          Si necesitas ayuda con algún proceso o no encuentras una solución, no dudes en abrir un ticket en nuestro sistema de soporte. Estamos aquí para asistirte.
        </Typography>
        {/* Search bar */}
        <Box sx={{ backgroundColor: 'white',
    borderRadius: 1,
    p: 1,
    maxWidth: 600,
    height: '56px',
    mx: 'auto',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center'}}>
        <IconButton color="primary" aria-label="search" component="span">
    <SearchIcon />
  </IconButton>
  <TextField
    placeholder="Haz una pregunta..."
    value={searchTerm}
    onChange={handleSearch}
    variant="outlined"
    fullWidth
    sx={{
      '& fieldset': { border: 'none' }, // Elimina el borde
      '& input': {
        padding: 0,  // Elimina el padding interno del texto
        height: '56px', // Ajusta la altura del input para que se vea correctamente
        lineHeight: '56px' // Alinea verticalmente el texto
      }
    }}
  />
          
        </Box>
      </Box>

      {/* Sidebar and Content */}
      <Box sx={{ display: 'flex', p: 3 }}>
        {/* Sidebar */}
        <Box sx={{ width: '20%', p: 2 }}>
          <Box sx={{ backgroundColor: '#002E74', color: 'white', p: 2, borderRadius: 2, mb: 2 }}>
            <Typography variant="h5">FAQ</Typography>
          </Box>
          <Typography variant="h4" sx={{ fontSize: '1.5em', mb: 2 }}>Preguntas Frecuentes</Typography>
        </Box>

        {/* FAQ Content */}
        <Box sx={{ width: '80%', p: 2 }}>
          {filteredFaqData.length > 0 ? (
            filteredFaqData.map((item, index) => (
              <FaqItem key={index} {...item} />
            ))
          ) : (
            <Typography variant="body1">No se encontraron resultados.</Typography>
          )}
        </Box>
      </Box>

      {/* Help Section */}
      <Box sx={{ backgroundColor: 'white', p: 3, m: 2, borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <HelpOutlineIcon sx={{ mr: 1, fontSize: 28, color: '#1E1E1E' }} />
          <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '24px', color: '#1E1E1E' }}>¿Necesitas ayuda?</Typography>
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>Si tienes algún problema o necesitas asistencia con la herramienta, déjanos un comentario aquí. Estamos para ayudarte.</Typography>
        <TextField
          multiline
          rows={4}
          placeholder="Escribe tu comentario aquí..."
          variant="outlined"
          fullWidth
          value={comment}
          onChange={handleCommentChange}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitComment}
          disabled={isSubmitting}
          fullWidth
          sx={{ backgroundColor: '#002E74', '&:hover': { backgroundColor: '#001A4D' } }}
        >
          {isSubmitting ? 'Enviando...' : 'Enviar comentario'}
        </Button>
      </Box>

      {/* Notificación */}
      <SimpleNotification
        open={notificationOpen}
        onClose={() => setNotificationOpen(false)}
        type={notificationType}
        message={notificationMessage}
      />
    </Box>
  );
};

export default FaqPage;
